var config = {
  // Set the Demo Version
  demo: false,

  // googleAddsense
  // googleAddsense: "",

  //SEO Configurations
  appName: "EdunepalPRO",
  metaDescription: "EdunepalPRO is a Web Quiz Application",
  metaKeyWords: "EdunepalPRO,Quiz,Questions,Answers,Online Quiz",
  //API Configurations
  apiAccessKey: 8525,
  apiUrl: "https://pos.edunepalpro.com/admin/api/",

  //Language Configurations
  // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
  supportedLanguages: ["en", "hi", "ur"],
  defaultLanguage: "en",

  // If your Default Language is not in supportedLanguages then add there first and after that set the defaultLanguage.

  //Quiz Configurations
  deductReviewAnswerCoins: 10, // 10 coins will be deducted if user Review the Answer
  deductIncorrectAnswerScore: 1, // This will deduct the points if user will give wrong answer
  deductLifeLineCoins: 1, // Deduct Coins when using Life Line

  // default country selection Configurations
  DefaultCountrySelectedInMobile: "np", //Default Country Selected in Mobile Login Screen

  // guess the word Quiz Configurations
  Guessthewordhintcoin: 5, //deduct coins guess the word

  // 1 vs 1 battle Quiz Configurations
  // matchrandomBattleSeconds: 30,
  battlecorrectanswer: 4,
  randomBattleSeconds:30, // 1 vs 1 battle timer seconds
  Randombattlecoins: 5, //deduct coins 1 vs 1 battle
  randomBattleoneToTwoSeconds: 2, // quick answer :- 1 vs 1 battle points 2 added on first 1 & 2 seconds
  randomBattlethreeToFourSeconds: 1, // quick answer :- 1 vs 1 battle points 1 added on first 3 & 4 seconds

  //Firebase Configurations

  /// For Firebase JS SDK v7.20.0 and later, measurementId is optional

  apiKey: "AIzaSyCDLs1z0XAXby1V034ZRG1fvcwyxHGqpfM",
  authDomain: "quiz-a7752.firebaseapp.com",
  databaseURL: "https://quiz-a7752-default-rtdb.firebaseio.com",
  projectId: "quiz-a7752",
  storageBucket: "quiz-a7752.appspot.com",
  messagingSenderId: "526766041587",
  appId: "1:526766041587:web:1fbd7686394d89720e8a86",
  measurementId: "G-FDWG587WTJ",


  //footer area
  companytext: "EdunepalPRO made with key principles of a beautiful, effective, simple to use and better code quality with use of functional based component.",
  addresstext: "Address: Janakpurdham",
  phonenumber: "+977 9885100002",
  email: "contact@edunepalpro.com",
  facebooklink: "#",
  instagramlink: "#",
  linkedinlink: "#",
  weblink: "https://edunepalpro.com/",
  companyname: "EdunepalPRO.",
};

export default config;
